<template>
  <div id="summary" class="card card-body">
    <div class="row align-items-center">
      <div class="summary__image-column col-4">
        <div class="position-relative ratio ratio-1x1 border-radius-lg overflow-hidden">
          <template v-if="profilePicture || profilePictureData">
            <img
              :alt="title"
              class="summary-visual__image"
              :src="profilePicture || profilePictureData"
            />

            <button
              class="summary-visual-remove position-absolute top-0 start-0 bottom-0 p-0 border-0
                     w-100 bg-transparent"
              @click="removeProfilePicture"
            >
              <div class="summary-visual-remove__background w-100 h-100 bg-danger" />

              <i
                class="position-absolute top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                       align-items-center material-icons text-light fs-2"
              >
                delete_outline
              </i>
            </button>
          </template>

          <label
            v-else
            class="d-flex justify-content-center align-items-center w-100 h-100 m-0 bg-gradient-dark
                   cursor-pointer"
            for="profilePictureData"
          >
            <i class="d-block material-icons text-secondary fs-2">
              add_a_photo
            </i>
            
            <input
              id="profilePictureData"
              accept="image/*"
              capture="user"
              class="d-none"
              type="file"
              @change="profilePictureChanged"
            />
          </label>
        </div>
      </div>
        <div class="summary__company-name-column col-8">
          <h5>{{ title }}</h5>
        </div>
    </div>
  </div>
</template>

<script>
import { createVModelHandlers } from "@/helpers/vue";
import { parseDateTimeRange } from '@/helpers/parsers';

import { getAll as getAllUsers } from '@/api/providers/users'

import { getDefaultAuthorId } from '@/helpers/organization-settings'

export default {
  name: 'CompanySummary',
  
  props: {
    showUpdate: {
      type: Boolean,
      default: true,
    },
    createdDateTime: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true,
    },
    profilePicture: {
      type: String,
      required: true,
    },
    profilePictureData: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      authors: []
    }
  },

  emits: [
    'update:profilePicture',
    'update:profilePictureData',
  ],

  mounted() {
    getAllUsers()
      .then(apiUsers => {
        this.authors = apiUsers.filter(u => u.isAdmin || u.id === this.authorUserId).map(u => {
          return {
            value: u.id, 
            label: `${u.firstName ?? ''} ${u.insertion ?? ''} ${u.lastName != null ? u.lastName.charAt(0) : ''}.`,
            image: u.profilePicture
          }
        })

        getDefaultAuthorId()
          .then(defaultAuthorId => {
            const index = this.authors.findIndex(u => u.value == defaultAuthorId);

            this.authorUserIdHandler = index > -1
              	? this.authors[index].value
                : this.authors[0].value
          })
          .catch(() => this.authorUserIdHandler = this.authors[0].value)
      })
  },

  computed: {
    ...createVModelHandlers(
      'authorUserId'
    ),

    parsedDateTimeRange() {
      return this.parseDateTimeRange(this.startDateTime, this.endDateTime);
    }
  },

  methods: {
    parseDateTimeRange,

    profilePictureChanged({ target }) {
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        this.$emit('update:profilePictureData', target.result);
      };
      reader.readAsDataURL(target.files[0]);
    },

    removeProfilePicture() {
      if (!confirm('Weet je zeker dat je de afbeelding wil verwijderen?')) {
        return;
      }

      this.$emit('update:profilePicture', '');
      this.$emit('update:profilePictureData', '');
    },
  },
};
</script>

<style scoped lang="scss">
.summary {
  &__image {
  
    &-column {
      width: 120px;
    }
  }
}

.summary-visual {

  &__image {
    aspect-ratio: 1;
    object-fit: cover;
  }

  &-remove {
    transition: opacity .2s;

    &:not(:hover) {
      opacity: 0;
    }

    &__background {
      opacity: .75;
    }
  }
}
.user-selector-dropdown {
  float:right;
  width:250px;
  .user-profile-image {
    margin-left: -3px;
    width:100px;
  }
}
</style>

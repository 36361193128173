import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(data) {
  return postRequest('/club', data);
}

export function edit(clubId, data) {
  return putRequest(`/club/${clubId}`, data)
}

export function get(id) {
  return getRequest(`/club/${id}`);
}

export function getAll() {
  return getRequest('/club');
}

export function remove(id) {
  return deleteRequest(`/club/${id}`);
}

export function addUser(clubId, userId) {
  return postRequest('/club/user', { clubId, userId });
}

export function removeUser(id, userId) {
  return deleteRequest(`/club/${id}/user/${userId}`);
}

export function getUserFunctions(clubId, userId) {
  return getRequest(`/club/user-function/${clubId}/${userId}`);
}

export function addUserFunction(clubId, userId, userFunctionId, functionDescription = null) {
  return putRequest('/club/user-function', { clubId, userId, userFunctionId, functionDescription });
}

export function removeUserFunction(clubId, userId, userFunctionId,) {
  return deleteRequest('/club/user-function', { clubId, userId, userFunctionId });
}

export function updateCustomUserFunction(data) {
  return postRequest('/club/user-function', data);
}
<template>
  <div class="col-lg-3">
    <side-nav
      :items="navigationItems"
    />
  </div>

  <div class="col-lg-9 mt-lg-0 mt-4">
    <company-summary
      v-model:profilePicture="fields.profilePicture"
      v-model:profilePictureData="fields.profilePictureData"
      :company-name="fields.clubName"
    />

    <form @submit.prevent="submit">
      <base-fieldset
        id="company-info"
        title="Clubinformatie"
      >
        <club-info
          v-model:clubName="fields.clubName"
          v-model:address="fields.address"
          v-model:zipCode="fields.zipCode"
          v-model:city="fields.city"
          v-model:country="fields.country"
          v-model:website="fields.website"
          v-model:description="fields.description"
          v-model:shortDescription="fields.shortDescription"
          v-model:phoneNumber="fields.phoneNumber"
          v-model:emailAddress="fields.emailAddress"
          :show-update="!isNewCompany"
        />
      </base-fieldset>

      <base-fieldset
        id="company-location"
        title="Locatie"
      >
        <company-location
          v-model:address="fields.address"
          v-model:zipCode="fields.zipCode"
          v-model:city="fields.city"
          v-model:country="fields.country"
          v-model:latitude="fields.latitude"
          v-model:longitude="fields.longitude"
          :show-update="!isNewCompany"
        />
      </base-fieldset>
      
      <base-fieldset
        id="user-socials"
        title="Socials"
      >
        <company-socials
          v-model:facebook="fields.facebook"
          v-model:linkedIn="fields.linkedIn"
          v-model:twitter="fields.twitter"
          :show-update="!isNewCompany"
        />
      </base-fieldset>

      <base-fieldset
        id="user-permissions"
        intro="Gebruikers binnen de app kunnen verschillende rechten hebben, vul hieronder 
          de juiste rechten in"
        title="Rechten"
      >
      </base-fieldset>
      
      <base-fieldset
        id="company-users"
        title="Betrokken personen"
      >
        <club-users 
          v-model:users="fields.clubUsers"
          :club-id="fields.id"
          :show-update="!isNewCompany"
          @update:user-functions-state="state => updatedUserFunctionsState = state"
          />
      </base-fieldset>
      
      <div
        v-if="!isNewCompany"
        id="delete"
        class="card mt-4"
      >
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Verwijder deze club</h5>
              <p class="text-sm mb-0">
                Wanneer een club verwijderd wordt, kan dit niet meer ongedaan gemaakt worden.
              </p>
            </div>
            <div class="w-50 text-end">
              <material-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                @click.prevent="remove"
              >
                Delete club
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <material-button
        v-if="isNewCompany"
        color="primary"
        variant="gradient"
        class="float-end mt-4 mb-0 mx-4"
      >
        Opslaan
      </material-button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { 
  add, 
  edit, 
  get, 
  remove,
  addUserFunction,
  removeUserFunction
} from '@/api/providers/club';

import { parseFullName } from '@/helpers/users';
import BaseFieldset from "@/components/Forms/BaseFieldset.vue";
import ClubInfo from "@/components/Club/Edit/ClubInfo.vue";
import CompanyLocation from "@/components/Company/Edit/CompanyLocation.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import SideNav from "@/components/UI/SideNav.vue";
import CompanySocials from "@/components/Company/Edit/CompanySocials.vue";
import ClubUsers from '../../components/Club/Edit/ClubUsers.vue';
import CompanySummary from '../../components/Company/Edit/CompanySummary.vue';
import { createYesNoSweetAlert } from "@/helpers/sweetalert"

export default {
  name: 'ClubEdit',

  components: {
    BaseFieldset,
    ClubInfo,
    CompanyLocation,
    CompanySocials,
    ClubUsers,
    MaterialButton,
    SideNav,
    CompanySummary
},

  data: () => ({
    fields: {
      id: 0,
      clubName: "",
      shortDescription: "",
      address: "",
      zipCode: "",
      city: "",
      country: "",
      longitude: 0,
      latitude: 0,
      phoneNumber: "",
      emailAddress: "",
      website: "",
      linkedIn: "",
      facebook: "",
      twitter: "",
      description: "",
      tagsList: [],
      organizationId: 0,
      profilePicture: '',
      profilePictureData: "",
      clubUsers: []
    },
    loading: false,
    updatedUserFunctionsState: []
  }),

  computed: {
    ...mapState('account', [
      'auth',
    ]),

    fullName() {
      const { firstName, insertion, lastName } = this.fields;
      return parseFullName(firstName, insertion, lastName);
    },

    isNewCompany() {
      return this.$route.params?.id === undefined;
    },

    navigationItems() {
      return [
        {
          href: '#company-info',
          icon: 'business',
          name: 'Clubinformatie',
        },
        {
          href: '#company-location',
          icon: 'map',
          name: 'Locatie',
        },
        {
          href: '#socials',
          icon: 'public',
          name: 'Social',
        },
        ...(!this.isNewCompany
          ? [{
              href: '#delete',
              icon: 'delete',
              name: 'Verwijder club',
            }]
          : []
        ),
      ];
    },

    submitData() {
      return {
        ...this.fields,
        companyUsers: null,
        ...(
          this.isNewCompany
            ? {}
            : {
              id: this.$route.params.id
            }
        ),
        organizationId: this.auth.organizationId,
      };
    },
  },

  mounted() {
    this.loadUserData();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    submitUpdatedUserFunctions(clubId) {
      return new Promise(resolve => {
        this.updatedUserFunctionsState.forEach(state => {
          return state.functionState === true
            ? addUserFunction(clubId, state.userId, state.userFunctionId, state.description)
            : removeUserFunction(clubId, state.userId, state.userFunctionId);
        });
        resolve();
      })
    },

    updateClubMembers(clubId) {
      return new Promise((resolve, reject) => {
          this.submitUpdatedUserFunctions(clubId).catch(() => reject());

          resolve();
      });
    },

    addClub() {
      add(this.submitData)
        .then(createdEntity => this.updateClubMembers(createdEntity.id))
          .then(this.handleSaveSuccess)

        .catch((error) => error.default())
          .then(() => {
            this.loading = false;
          });
    },

    editClub() {
      edit(this.$route.params?.id, this.submitData)
        .then(this.updateClubMembers(this.$route.params?.id))
          .then(this.handleSaveSuccess)
        
        .catch((error) => error.default())
        .then(() => {
          this.loading = false;
        });
    },

    handleGetSuccess(data) {
      Object.entries(data).forEach(([key, value]) => {
        if (!(key in this.fields)) {
          return;
        }

        this.fields[key] = value;
      });
      
      this.sortUserByFunctionPriority();
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `${this.fields.clubName} is opgeslagen.`,
        icon: {
          component: 'apartment',
        },
      });

      this.navigateToClubOverview();
    },

    loadUserData() {
      if (this.isNewCompany) {
        return;
      }

      get(this.$route.params.id)
        .then(this.handleGetSuccess)
        .catch((error) => error.default());
    },

    navigateToClubOverview() {
      this.$router.push({
        name: 'clubs',
      });
    },

    remove() {
      createYesNoSweetAlert(
        `Club ${this.fields.clubName} verwijderen?`,
        () => { 
          remove(this.$route.params.id, this.fullName)
            .then(this.navigateToClubOverview)
            .catch((error) => error.default());
        }
      )
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.isNewCompany) {
        this.addClub();
      } else {
        this.editClub();
      }
    },
    
    getLowestFunctionId(functions) { 
      return Math.min(...(functions.map(f => f.userFunctionId)));
    },

    sortUserByFunctionPriority() {
      this.fields.clubUsers = this.fields.clubUsers.sort(
        (a, b) => this.getLowestFunctionId(a.functions) > this.getLowestFunctionId(b.functions)
      );
    }
  },
}
</script>

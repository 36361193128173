<template>
  <material-switch
    :id="`switch-${userId}-${functionId}`"
    :name="`switch-${userId}-${functionId}`"
    :modelValue="state"
    @update:modelValue="toggleFunction"
  />
</template>

<script>
import MaterialSwitch from '../../MaterialSwitch.vue';

export default {
  components: { 
    MaterialSwitch
  },

  props: {
    userId: { type: Number, required: true },
    clubId: { type: Number, required: true },
    functionId: { type: Number, required: true },
    state: { type: Boolean, required: true }  
  },

  emits: [
    "update:state"
  ],

  methods: {
    toggleFunction(functionState) {
      this.$emit(
        "update:state",
        {
          clubId: this.clubId,
          userFunctionId: this.functionId,
          userId: this.userId,
          functionState
        }
      );
    }
  }
}
</script>
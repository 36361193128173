<template>
  <div
    :style="functionColumnStyle"
  >
    <div 
      class="function column"
    >
      <div 
        class="custom-function-input d-flex mb-2 w-100"
      >
        <MaterialInput
          v-model="customFunctionHandler"
          class="me-1 w-100" 
          placeholder="Functie"
          @update:modelValueHandler="customFunctionHandler"
          @change="saveCustomFunction"
          @keydown.enter.prevent=""
        />

        <div 
          v-if="showControls"
          type="button" 
          class="btn btn-sm btn-primary mb-0 d-flex justify-content-center align-items-center"
          @click.prevent="removeCustomFunction"
        >
          <i 
            class="top-0 start-0 bottom-0 end-0 align-items-center material-icons text-light fs-4"
          >
            delete_outline
          </i>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import MaterialInput from '@/components/MaterialInput.vue';

import { CUSTOM_USER_FUNCTION_ID } from "@/helpers/user-functions"

export default {
  name: 'UserFunctionModalCustomInput',

  components: {
    MaterialInput
  },

  props: {
    clubId: { type: Number, required: false, default: 0 },
    userId: { type: Number, required: true, },
    customFunctionString: { type: String, default: '' }
  },

  emits: [
    "custom-function-updated",
  ],

  data() {
    return {
      updatedInputString: '',
      userFunctionDeleted: false
    }
  },

  computed: {
    customFunctionHandler: {
      get() {
        return this.userFunctionDeleted ||this.updatedInputString?.length > 0
          ? this.updatedInputString
          : this.customFunctionString
      },
      set(value) {
        this.updatedInputString = value
      }
    },
    showControls() { return this.customFunctionHandler?.length > 0; }
  },

  methods: {
    saveCustomFunction()
    {
      this.$emit("custom-function-updated", {
        clubId: this.clubId,
        userFunctionId: CUSTOM_USER_FUNCTION_ID,
        userId: this.userId,
        functionState: true,
        description: this.customFunctionHandler,
      });
    },

    removeCustomFunction()
    {
      this.customFunctionNameHandler = '';
      this.updatedInputString = '';
      this.userFunctionDeleted = true;

      this.$emit("custom-function-updated", {
        clubId: this.clubId,
        userFunctionId: CUSTOM_USER_FUNCTION_ID,
        userId: this.userId,
        functionState: true,
        description: undefined,
      });
    }
  }
}
</script>
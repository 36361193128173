<template>
  <div class="col-4">
    <dropdown
      label="Gebruiker toevoegen"
      :options="apiUsers"
      @update:value="handleUserDropDownEvent"
    />
</div>
  <div
    v-for="user in users"
    :key="user.id"
    class="row mt-2 d-flex align-items-center flex-row"
  >
    <div class="col d-flex align-items-center">
      <profile-picture
        :width-and-height-in-pixels="50"
        :background-image-url="user.profilePicture"
      />
      <h6 class="ms-4"> 
        <router-link
          :to="`/app-users/${user.id}`"
        >
          {{ user.userName }}
        </router-link>
        <span class="user-functions-list">
          {{ createFunctionsString(user) }}
        </span>
      </h6>
    </div>

    <div class="col d-flex align-items-center justify-content-end">
      <button
        class="btn btn-primary btn-sm float-end d-inline mb-0 me-2"
        title="Functies aanpassen"
        :style="{ width: '50px' }"
        @click.prevent="() => editUserFunctions(user)"
      >
        <i
          class="top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                  align-items-center material-icons text-light fs-4"
        >
          tune
        </i>
      </button>
       
      <button
        class="btn btn-primary btn-sm float-end d-inline mb-0"
        :style="{ width: '50px' }"
        @click.prevent="e => removeUser(e, user)"
      >
        <i
          class="top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                  align-items-center material-icons text-light fs-4"
        >
          delete_outline
        </i>
      </button>
    </div>
  </div>
  <div class="row mt-4">
    <div 
      class="col-12"
    >
      <material-button
        v-if="showUpdate"
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="submitUpdatedUsers"
      >
        Update gegevens
      </material-button>
    </div>
  </div>

  <user-functions-modal
    :user-name="modal.userName"
    :functions-for-user="modal.functionsForUser"
    :club-id="clubId"
    :user-id="modal.userId"
    :custom-function-name="modal.customUserFunctionString"
    @update:user-state="updateUserFunctionDictionary"
  />

</template>

<script>
import { Modal } from 'bootstrap';
import { removeUser as removeClubUser, getUserFunctions } from "@/api/providers/club";
import { getAll } from "@/api/providers/users";

import Dropdown from '../../UI/Dropdown.vue';

import MaterialButton from '@/components/MaterialButton.vue';
import UserFunctionsModal from '@/components/Club/Edit/UserFunctionsModal.vue';
import { createYesNoSweetAlert } from '@/helpers/sweetalert.js'

import { CUSTOM_USER_FUNCTION_ID } from '../../../helpers/user-functions';

export default {
  name: 'ClubUsers',

  components: {
    Dropdown,
    MaterialButton,
    UserFunctionsModal
  },

  props: {
      clubId: { type: Number, required: true },
      users: { type: Array, required: true },
      showUpdate: { type: Boolean, default: true }
  },

  emits: [
    'update:users',
    'update:user-functions-state'
  ],

  data() {
    return {
      currentCompanyUsers: undefined,
      apiUsers: [],

      modal: {
        userName: '',
        userId: 0,
        element: undefined,
        functionsForUser: [],
        customUserFunctionString: ''
      },

      updatedUserFunctions: []
    }
  },

  mounted() {
    getAll()
      .then(fullUserList => {
        this.apiUsers = fullUserList.map(u => {
          return {
            value: u.id, 
            label: `${u.firstName ?? ''} ${u.insertion ?? ''} ${u.lastName ?? ''}.`,
            image: u.profilePicture
          };
        })
      });

      this.modal.element = new Modal('#user-function-modal', {});
  },

  computed: {
    usersHandler: {
      get() { 
        return this.currentCompanyUsers !== undefined
        ? this.currentCompanyUsers
        : this.users;
      },
      set(value)
      {
        this.currentCompanyUsers = value;
        this.$emit('update:users', this.usersHandler);
      }
    }
  },

  methods: {
    addUser(userId) {
      const user = this.apiUsers.find(u => u.value === userId);

      if (user !== undefined)
      {
        this.usersHandler = this.usersHandler.concat([{
          id: user.value,
          userName: user.label,
          profilePicture: user.image,
          isNew: true
        }]);
      }
    },
    
    removeUser(e, user) {
      e.preventDefault();

      createYesNoSweetAlert(
        `Weet u zeker dat u ${user.userName} wilt verwijderen?`,
        () => {
          if (this.$route.params?.id !== undefined) {
            removeClubUser(this.$route.params?.id, user.id);
            this.usersHandler = this.usersHandler.filter(u => u != user);
            return;
          }

          this.usersHandler = this.usersHandler.filter(u => u != user);
          this.updatedUserFunctions = this.updatedUserFunctions.filter(e => e.userId != user.id);
          this.$emit('update:user-functions-state', this.updatedUserFunctions);
        });
      return;
    },

    markUserAsDeleted(user) {
      let users = this.usersHandler;
      let index = users.findIndex(u => u === user);
      if (index !== -1) {
        users[index] = { ...user, isDeleted: true };
        this.usersHandler = users;
      }
    },

    async editUserFunctions(user) {
      this.modal.userName = user.userName;
      this.modal.userId = user.id;

      try {
        let functions = await getUserFunctions(this.clubId, user.id);
        
        this.modal.functionsForUser = functions.concat(
          this.updatedUserFunctions.filter(f => 
            f.userId == user.id &&
            f.clubId == (this.$route.params?.id ?? 0)
        ));

        this.modal.functionsForUser = functions;

        const customFunction = functions.find(f => f.userFunctionId === CUSTOM_USER_FUNCTION_ID);
        
        this.modal.customUserFunctionString = customFunction != undefined
          ? customFunction.functionDescription
          : '';
      } catch {
        this.modal.functionsForUser = this.updatedUserFunctions.filter(f => 
          f.userId == user.id &&
          f.clubId == (this.$route.params?.id ?? 0
        ));

        this.modal.customUserFunctionString = '';
      }

      this.modal.element.show();
    },

    createFunctionsString(user) {
      if (user.functions === undefined || user.functions.length === 0) return "";

      const sortedFunctions = user.functions.sort((a, b) => a.userFunctionId > b.userFunctionId);
      
      let functionsString = sortedFunctions
        .filter(item => item.userFunction != '')
        .filter((item, index) => index < 3)
        .map(f => f.functionDescription).join(', ');
    
      if (sortedFunctions.length > 3) functionsString += '...';
      return functionsString;
    },

    handleUserDropDownEvent(userId)
    {
      this.addUser(userId);
      this.updateUserFunctionDictionary({ 
        userId, 
        userFunctionId: CUSTOM_USER_FUNCTION_ID, 
        functionState: true
      });
    },

    updateUserFunctionDictionary(data) {
      const element = this.updatedUserFunctions.find(e => 
        e.userId === data.userId && 
        e.userFunctionId === data.userFunctionId && 
        e.functionState !== data.functionState
      ); 

      if (element !== undefined) {
        this.updateAlreadyInsertedUserFunction(element, data);
        return;
      }
      
      if (!this.updateCustomUserFunction(data)) this.insertNewUserFunction(data);
    },

    updateAlreadyInsertedUserFunction(element, data) {
      const index = this.updatedUserFunctions.indexOf(element);
      this.updatedUserFunctions[index] = data;
      this.$emit('update:user-functions-state', this.updatedUserFunctions);
    },

    insertNewUserFunction(data) {
      this.updatedUserFunctions.push(data);
      this.$emit('update:user-functions-state', this.updatedUserFunctions);
    },

    updateCustomUserFunction(data) {
      const element = this.updatedUserFunctions.find(e => 
        e.userId === data.userId && 
        e.userFunctionId === CUSTOM_USER_FUNCTION_ID
      ); 

      if (
        element === undefined || 
        element.description === undefined ||
        element.description === data.description
      ) return false;

      const index = this.updatedUserFunctions.indexOf(element);
      this.updatedUserFunctions[index] = data;
      this.$emit('update:user-functions-state', this.updatedUserFunctions);

      return true;
    }
  },
}
</script>

<style>
.user-functions-list {
  display: block;
  font-weight: normal;
  font-size: 13px;
}
</style>
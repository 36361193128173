<template>
  <div
    class="modal fade" 
    id="user-function-modal" 
    tabindex="-1" 
    aria-labelledby="user-function-modal" 
    aria-hidden="true"
    style="z-index: 99999"
  >
    <div 
      class="modal-dialog modal-xl"
    >
      <div 
        class="modal-content"
      >
        <div 
          class="modal-header"
        >
          <h5
            class="modal-title" 
            id="exampleModalLabel"
          >
            Functies voor {{ userName }}
          </h5>
        </div>
        <div class="modal-body">
          <div 
            class="row"
          >
            <div class="col-3 board">
              <h3>Bestuur</h3>
              <div 
                class="function-column"
              >
                <div 
                  v-for="userFunction in boardFunctions"
                  v-bind:key="userFunction.id"
                  class="function"
                >
                  <UserFunctionModalSwitch  
                    :userId="userId"
                    :clubId="clubId"
                    :functionId="userFunction.id"
                    :state="userHasFunction(userFunction.id)"
                    @update:state="updateUserFunctionState"
                  />
                  <span>{{ userFunction.title }}</span>
                </div>
              </div>
            </div>
            <div class="col-3 commission">
              <h3>Commissielid</h3>
              <div 
                class="function-column"
              >
                <div 
                  v-for="userFunction in commissionFunctions"
                  v-bind:key="userFunction.id"
                  class="function"
                >
                 <UserFunctionModalSwitch  
                    :userId="userId"
                    :clubId="clubId"
                    :functionId="userFunction.id"
                    :state="userHasFunction(userFunction.id)"
                    @update:state="updateUserFunctionState"
                  />
                  <span>{{ userFunction.title }}</span>
                </div>
              </div>
            </div>
            <div class="col-3 professional">
              <h3>Professional</h3>
              <div 
                class="function-column"
              >
                <div 
                  v-for="userFunction in professionalFunctions"
                  v-bind:key="userFunction.id"
                  class="function"
                >
                  <UserFunctionModalSwitch  
                    :userId="userId"
                    :clubId="clubId"
                    :functionId="userFunction.id"
                    :state="userHasFunction(userFunction.id)"
                    @update:state="updateUserFunctionState"
                  />
                  <span>{{ userFunction.title }}</span>
                </div>
              </div>
            </div>
            <div class="col-3 other">
              <h3>Anders</h3>
              <user-function-modal-custom-input 
                :club-id="clubId"
                :user-id="userId"
                :custom-function-string="customFunctionName"
                @custom-function-updated="updateUserFunctionState"
              />
            </div>
          </div>
        </div>
        <div 
          class="modal-footer"
        >
          <button 
            type="button" 
            class="btn btn-sm btn-primary mb-0" 
            data-bs-dismiss="modal" 
            aria-label="Close"
          >
            Sluiten
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { get as getUserFunctions } from '@/api/providers/user-functions';

import UserFunctionModalSwitch from '@/components/Club/Edit/UserFunctionModalSwitch.vue';

import UserFunctionModalCustomInput from './UserFunctionModalCustomInput.vue';

const BOARD_FUNCTION = 0;
const COMMISSION_FUNCTION = 1;
const PROFESSIONAL_FUNCTION = 2;

export default {
  name: "UserFunctionsModal",
  
  components: {
    UserFunctionModalSwitch,
    UserFunctionModalCustomInput
  },

  props: {
    userName: { type:String, default: '' },
    userId: { type: Number, default: 0 },
    clubId:  { type: Number, default: 0 },
    functionsForUser: { type: Array, default: () => [] },
    customFunctionName: { type: String, default: '' }
  },

  emits: [
    "update:user-state"
  ],

  data() {
    return {
      boardFunctions: [],
      commissionFunctions: [],
      professionalFunctions: [],
      userFunctionDeleted: false,
      updatedInputString: ''
    }
  },

  computed: {
    customFunctionNameHandler: {
      get() {
        return this.updatedInputString.length > 0
          ? this.updatedInputString 
          : this.customFunctionName;
      },
      set(value) {
        this.updatedInputString = value;
      }
    },
  },

  mounted()
  {
    this.initializeUserFunctions();
  },

  methods:
  {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    updateUserFunctionState(data)
    {
      this.$emit("update:user-state", data)
    },

    initializeUserFunctions: async function() {
      try {
        let functions = await getUserFunctions();

        this.boardFunctions = functions.filter(f => f.type == BOARD_FUNCTION);
        this.commissionFunctions = functions.filter(f => f.type == COMMISSION_FUNCTION);
        this.professionalFunctions = functions.filter(f => f.type == PROFESSIONAL_FUNCTION);

      } catch {
        this.boardFunctions = [];
        this.commissionFunctions = [];
        this.professionalFunctions = [];
      }
    },

    userHasFunction(functionId) {
      return this.functionsForUser.filter(f => f.userFunctionId == functionId).length > 0;
    },
  },
}

</script>

<style scoped>
.function {
  display: flex;
  align-items: center;

  padding: 0 10px;
  margin-bottom: 15px;
}

.function.column {
  flex-direction: column;
  padding: 0;
}

.function-column {
  max-height: 450px;
  overflow-y: scroll;
}
</style>